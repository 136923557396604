import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-1.png";
import { Navbar } from "./navbar";
import "../assets/css/header.css";

const Header = () => {
  const [searchInput, setSearchInput] = useState("");
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const navigate = useNavigate();
  const mobileNavToggle = (e) => {
    e.preventDefault();
    setMobileNavActive(!mobileNavActive);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchInput.length >= 2) {
      navigate("/search", { state: searchInput, replace: true });
    }
  };
  useEffect(() => {
    setMobileNavActive(false);
  }, []);
  return (
    <>
      <header
        id="header"
        className="d-flex align-items-center header-transparent "
      >
        <div className="container">
          <div className="logo mobile-show">
            <h1 className="text-light">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </h1>
          </div>
          <div className={`${mobileNavActive ? "navbar-mobile" : ""}`}>
            <div
              className={`${
                mobileNavActive ? "" : "d-flex"
              } align-items-center justify-content-between mobile-menu`}
            >
              <div className="logo mobile-hide">
                <h1 className="text-light">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </h1>
              </div>
              <Navbar mobileNavToggle={mobileNavToggle} />
              <form className="d-flex serch-form" onSubmit={handleSearch}>
                <input
                  className="form-control search-btn-value"
                  type="search"
                  placeholder="Search "
                  aria-label="Search"
                  onChange={handleChange}
                  value={searchInput}
                />
                <button className="search-form" onClick={handleSearch}>
                  <i className="fa-solid fa-magnifying-glass search-icon-value"></i>
                </button>
              </form>
            </div>
            <i
              className="fa fa-bars mobile-nav-toggle"
              onClick={mobileNavToggle}
            ></i>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
