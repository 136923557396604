import { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./dropdown";

const MenuItems = ({ items, onClick }) => {
  const [submenuActive, setSubmenuActive] = useState(false);
  const submenuToggle = (e) => {
    e.preventDefault();
    setSubmenuActive(!submenuActive);
  };
  return (
    <>
      {items && (
        <li
          className={`${items.className ? items.className : ""} ${
            submenuActive ? "dropdown-active" : ""
          }`}
          onClick={submenuToggle}
        >
          {items.submenu ? (
            <>
              <Link to={items.url}>
                {items.title} <i className="fa fa-chevron-down"></i>
              </Link>
              <Dropdown submenus={items.submenu} onClick={onClick} />
            </>
          ) : (
            <Link to={items.url}>{items.title}</Link>
          )}
        </li>
      )}
    </>
  );
};

export default MenuItems;
