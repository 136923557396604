import CustomLink from "./customLink";

const Dropdown = ({ submenus, ...props }) => {
  return (
    <ul className="">
      {submenus.map((submenu, index) => (
        <CustomLink key={index} to={submenu.url} {...props}>
          {submenu.title}
        </CustomLink>
      ))}
    </ul>
  );
};

export default Dropdown;
