import React from "react";
import { Link } from "react-router-dom";
import PageNotFound from "../assets/images/error-404.jpg";
const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <Link to="/">Go to Home </Link>
      </p>
      <img style={{ maxWidth: "100%" }} src={PageNotFound} alt="" />
      <p>
        <Link to="/">Go to Home </Link>
      </p>
    </div>
  );
};
export default NotFoundPage;
