import "./App.css";
import Header from "./layouts/header";
import { Footer } from "./layouts/footer";
import { Route, Routes } from "react-router-dom";
import { About } from "./pages/about";
import { Contact } from "./pages/contact";

// Template Main CSS File -->
import "./assets/css/style.css";
import "./assets/css/media.css";
import NotFoundPage from "./pages/not-found-page";
import { Search } from "./pages/search";

function App() {
  return (
    <>
      <Header></Header>
      <div className="forMenu">
        <Routes>
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/search" element={<Search />} />
          <Route path="*" element={<About />} />
          <Route path="/not-found" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
