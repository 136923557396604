import axios from "axios";

const token = process.env.REACT_APP_API_KEY;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default instance;
