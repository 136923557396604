import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "../axios";
import parse from "html-react-parser";

export const Search = () => {
  const [content, setContent] = useState([]);

  const { state } = useLocation();
  useEffect(() => {
    axios
      .post("v1/records-with-count?limit=50&offset=0", {
        tableId: process.env.REACT_APP_CM_TABLE_ID,
        extraArgs: {
          customQueryArray: [
            {
              operator: "=",
              column: "text_3",
              value: "Active",
            },
            {
              join: "AND",
            },
            {
              operator: "like",
              column: "long_text_1",
              value: `${state || ""}`,
            },
          ],
        },
      })
      .then((response) => {
        setContent(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [state]);
  const truncate = (str) => {
    return str.length > 100 ? str.substring(0, 7) + "..." : str;
  };
  return (
    <>
      <main id="main">
        <section className="search-page">
          <div className="container-fluid container-fluid-equal-space">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                  Search Result
                </span>
              </div>
            </h2>
            {content && (
              <div>
                <ul style={{ marginBottom: 0 }}>
                  {Object.entries(content || {}).map(([prop, value]) => {
                    return (
                      <li key={prop}>
                        <h5>{value.text2}</h5>
                        <div>{parse(truncate(value.longText1))}</div>
                        <Link to={value.text5}>Read more {">>"}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
};
